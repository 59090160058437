<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading" rounded="md">
            <b-card no-body>
              <b-card-header class="border-0">
                <h3 class="mb-0 d-inline">{{ pageName }}</h3>
              </b-card-header>
              <b-table
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                stacked="md"
                show-empty
                small
                hover
              >
                <template #cell(created_dt)="row">
                  <router-link :to="{ name: 'expense-upload-detail', params: { id: row.item.id } }">
                    {{ showDatetime(row.value) }}
                  </router-link>
                </template>
                <template #cell(is_all_sent)="row">
                  <b-icon v-if="row.value" icon="check-circle-fill" variant="success"></b-icon>
                  <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
                </template>
              </b-table>
              <b-card-footer>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="md"
                ></b-pagination>
              </b-card-footer>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { ajax, common, config } from '../../utils';
import { helper } from '../common';

export default {
  data() {
    return {
      loading: true,
      items: [],
      fields: [
        { key: 'created_dt', label: '導入日時' },
        { key: 'payment_slip_count', label: '給与明細数', class: 'text-center' },
        { key: 'withholding_count', label: '源泉票数', class: 'text-center' },
        { key: 'is_all_sent', label: '送信済', class: 'text-center' },
        { key: 'user_name', label: '導入者' },
      ],
      currentPage: 1,
      perPage: config.rowsPerPage,
    }
  },
  computed: {
    pageName: function() {
      return common.getPageName(this.$route.name);
    },
    totalRows: function() {
      return this.items.length;
    },
  },
  created: function () {
    document.title = this.pageName;
    this.getUploadList();
  },
  methods: {
    getUploadList() {
      const vm = this;
      ajax.fetchGet(config.api.expense.upload_list).then(data => {
        vm.items = data.results;
      }).catch(errors => {
        helper.show_errors(errors);
      }).finally(() => {
        vm.loading = false;
      });
    },
    showDatetime: function(d) {
      return common.formatDate(new Date(d), 'YYYY/MM/DD hh:mm')
    },
  },
}
</script>